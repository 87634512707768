 import React from "react";
 import '../../App.css';
 import HeroSection from '../Herosection.js';
 import ServiceSection from '../ServiceSection.js';
 import Footer from "../Footer";
 import HomeImages from "../HomeImages";
 import WhyUs from "../WhyUs";
 import { Helmet } from "react-helmet";




function Home() {
  return (
    <>
    <Helmet>
        <title>Viktoriyastad AB</title>
            <link rel="canonical" href="https://www.victoriastad.se/" />
            <meta keywords="städfirma,städfirma stockholm pris,storstädning,städning,städa,städhjälp,
    städer i sverige,städ stockholm,städ hemma,städ åhus,cleaning company,städ danderyd,
    städfirma salem,städ täby,städ norrtälje,städ ekerö,städ eskilstuna, städ enköping,
    städ flytt,städföretag,städ flytt stockholm,städ f,städ i stockholm,städ i hemmet,städ 
    järfälla,städ julkalender,städ köpa,städ bjärnum,städ bromma,städ alla bolag,städ lidingö,
    städmopp,städmaterial,städ och tvätt,städ och service,städer i,städ pris,städ rut,
    RUT- företag,Rotavdrag,städ södertälje,städ sollentuna,städ upplands väsby,städ uppsala,
    städfirma haninge,städfirma huddinge,städfirma täby,städfirma solna,städfirma skåne,
    städfirma söderham,städfirma katrineholm,    städfirma söderham,städfirma katrineholm
    flytt firma,flytt städ,flytt och städ,stad i ljus,stockholm stad,storstäd,restaurang storstad,flyttstädning,flyttstädning stockholm,flyttstädning priser,boka,kontorsstäd,kontorsstäd stockholm,
    fönsterputs,fönsterputs stockholm,fönsterputs pris,barnpassning,
    barnpassning stockholm,barnpassning pris,barnpassning nära dig,
    rutavdrag barnpassning,FÖRETAGSTJÄNSTER,FÖNSTERPUTS,FLEXIBEL TRAPP OCH ENTRE-STÄDNIN, 
    GARANTERAT NÖJD,ENKEL BETALNING,veckostädning pris,dry cleaning,cleaning,
    steam cleaning,cleaning house" />
            <meta description="Vi utför flertal sanerings- & städtjänster. Vi utför jobbet till rätt pris med kundfokus. !"  />
            <meta name="description " content="victoriastad.se" data-react-helmet="true"/>
      </Helmet>
    <HeroSection/>
    <WhyUs/>
    <ServiceSection/>
     <HomeImages/> 
    <Footer/>
    </>
  );
}

export default Home;