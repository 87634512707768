import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import './HomeImages.css';
import 'bootstrap/dist/css/bootstrap.css';
import Fade from 'react-reveal/Fade'

function HomeImages() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (

    <div id="homeImages" className="homeImages-container flex ">
      
      <h3 className="title text-center ">VI ÄLSKAR DET VI GÖR</h3>
      
      <div className="row">
    <div className="col-sm img-cont">
    <Fade left><img className="home-images" src="../images/hi-1.jpg"  path="/about" alt=""/></Fade>
  
    </div>
    <div className="col-sm img-cont">
    <Fade top><img className="home-images" src="../images/hi-2.jpg"  path="/about" alt=""/></Fade>
  
    </div>
    <div className="col-sm img-cont">
    <Fade bottom><img className="home-images" src="../images/hi-3.jpg"  path="/about" alt=""/></Fade>
    
    </div>
  </div>

    </div>

        );
      }
     
       
 
  
export default HomeImages;