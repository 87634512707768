import React from "react";
import "./Herosection.css";
import "bootstrap/dist/css/bootstrap.min.css";

function HeroSection() {
  return (
    <div className="hero-container" id="hero">
      <div className="clean">

          <div className="allTitle text-left">
            <div className="hero-title1  ">
              {" "}
           HEMSTÄDNING
            </div>
            <div className="hero-title2 ">
              {" "}
              Vi ger den bästa servicen
              <br /> till de bästa priserna{" "}
            </div>
          </div>
        </div>
      </div>
    
  );
}

export default HeroSection;
