import React from "react";
import Zoom from 'react-reveal/Zoom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ServiceSection.css';



 

const ServiceSection = () => {
  return ( 

    <div className="cont-services icon-box pb-5"  id='services'>

     <div className="carts-circle">
  <h3 className=" title text-center">TJÄNSTER</h3>

  <div className="grid-container row mx-auto">
   
      <div className="cart" >
      <Zoom >  <div className=" image-one img-all"></div> </Zoom>
        <div className="title-services text-center"><h4>Hemstäd</h4> 
        <p>Vi har utfört hemstädning i Stockholm i många år. Vi har en lång lista med
         trogna kunder – inte minst många som gått i arv i generationer till barn och
          barnbarn. Den typen av förtroende får man bara om man utför ett mycket bra jobb.</p>
          <strong>Pris: 150kr /Tim efter Rut avdrag inkl moms</strong>
 </div>
     
    </div>

   
      <div className="cart"  >
       <Zoom> <div className="image-two img-all"> </div></Zoom>
        <div className="title-services text-center"> <h4>Kontorsstädning</h4> 
        <p>
        Vi skräddarsyr kontorsstädning för att anpassa efter era behov. 
        Viktoriyastad erbjuder komplett kontorsstädning där städmaterial ingår 
        i tjänsten. Ett välstädat kontor ökar trivseln och bidrar till lönsammare företag!
</p>  <strong>Pris: 270kr/tim exkl moms</strong>
</div>
     
    </div>

   
      <div className="cart"  >
       <Zoom> <div className="image-three img-all" > </div></Zoom>
        <div className="title-services text-center"><h4>Fönsterputs</h4>
        <p>Vem uppskattar inte att ha rena fönster, hemma och på arbetsplatsen. 
        Vi putsar alla slags fönster, högt och lågt. Vi använder även Ultrarent
         (avjoniserat) vatten till svåråtkomliga glaspartier på hög höjd och fasader 
         som behöver rengöras. </p></div>
     
    </div>

    
      <div className="cart" to="/services#Storstadning" >
       <Zoom> <div className="image-four img-all"> </div></Zoom>
        <div className="title-services text-center"><h4>Flyttstäd</h4> 
        <p>Att flytta till ett nytt hem är en av de största händelserna i livet. Varför inte låta 
        oss ta hand om flyttstädningen så kan du själv ägna dig åt mer inspirerande saker?
         Vi erbjuder effektiv, skonsam och serviceinriktad flyttstädning i Stockholm.</p>
         <strong>Pris: 30kr /kvm efter Rut avdrag </strong>
         </div>
     
    </div>

   
      <div className="cart" >
       <Zoom> <div className="image-five img-all" > </div></Zoom>
        <div className="title-services text-center"><h4>Trappstädning</h4>
        <p>I vårt breda utbud tjänster ingår även trappstädning. Vi kan se till att
         ditt trapphus håller sig i toppskick. Vi utför trappstädning åt såväl privatpersoner, företag och bostadsrättsföreningar.</p>
        </div>
      
    </div> 

  
      <div className="cart" t >
      <Zoom>  <div className="image-six img-all" > </div></Zoom>
        <div className="title-services text-center"><h4>Byggstädning</h4>
        <p>Vi har stor erfarenhet av att sköta om städningen på byggarbetsplatsen 
        under en byggnation eller renovering. Vi tar gärna hand om slutstädningen också. 
        Kontakta oss för mer informaton om byggstädning i Stockholm!</p>
        <strong>Pris: 40kr /kvm</strong> </div>
     
    </div>
   

  </div>
</div> 


  
       </div>
  );
};

export default ServiceSection;
 