import React from 'react';
import '../App.css';
import './WhyUs.css'
import Fade from 'react-reveal/Fade';
import 'bootstrap/dist/css/bootstrap.css';


export default function MassaheHome() {
  return (

    <div className="massage-home" id="about">
       <h1 className="title why">Om oss</h1>

      
  <div className="grid-container ">
<Fade left>
  <div className="pics1"></div>
</Fade>
  <div className="content-pics">
  <h3>Välkommen till Viktoriyastad</h3>
  <p>På  Viktoriyastad AB i Stockholm är vi stolta över det vi gör. Vi är en städfirma med över 13 års 
  erfarenhet och framför allt Stockholms bästa städpersonal. Vi är säkra på att du kommer att bli nöjd 
  med oss och vi erbjuder nöjd kund-garanti på våra hemstäd.</p>
  <p>
  {/* Viktoriyastad - ditt städföretag i Stockholm 
  med omnejd. */}
   Vi erbjuder våra tjänster till både företag och privatpersoner.
  Vårt största mål är nöjda kunder som återkommer, och vi har därför gjort oss 
  kända för att vara flexibla, snabba och pålitliga.Vi städar på tider som passar er, 
  med kemikalier som är snälla mot både er och miljön.</p>
  </div>
  
 <Fade left>
  <div className="pics2"></div>
  </Fade>
  <div className="content-pics ">
  <h3>100% nöjd kund-garanti</h3>
  <p>Alla städare hos Viktoriyastad är noggranna,
   effektiva, punktliga och kunniga om städbranschen. Att arbeta som städare 
   kräver att man tar sitt ansvar, är serviceminded och kan ta egna initiativ!
    Vi är stolta över att vara ett etablerat städföretag i Stockholm! 
   <ul>
     <li>Alltid samma person som städar, tryggt och bra</li>
     <li>Personlig och lyhörd kontaktperson som du alltid kan ringa</li>
     <li>Utbildad och erfaren personal, kontrollerad mot polisens register</li>
     <li>En kvalitetsansvarig som säkerställer att vi håller vad vi lovar</li>
     <li>Automatisk hantering av RUT-avdraget</li>
     <li>Heltäckande ansvarsförsäkringar</li>
   </ul> <p>

</p>
    
    Kontakta 
    oss om du vill ha hjälp med städ i Stockholm 
  med omnejd!</p></div>
 

</div>

    </div>      
  
    );
  }

